@import "functions";

/* BEM */
@mixin b($block) {
  $B: $namespace+'-'+$block !global;

  .#{$B} {
    @content;
  }
}

@mixin e($element) {
  $E: $element !global;
  $selector: &;
  $currentSelector: "";
  @each $unit in $element {
    $currentSelector: #{$currentSelector + "." + $B + $element-separator + $unit + ","};
  }

  @if hitAllSpecialNestRule($selector) {
    @at-root {
      #{$selector} {
        #{$currentSelector} {
          @content;
        }
      }
    }
  } @else {
    @at-root {
      #{$currentSelector} {
        @content;
      }
    }
  }
}

@mixin m($modifier) {
  $selector: &;
  $currentSelector: "";
  @each $unit in $modifier {
    $currentSelector: #{$currentSelector + & + $modifier-separator + $unit + ","};
  }

  @at-root {
    #{$currentSelector} {
      @content;
    }
  }
}

@mixin when($state) {
  $currentSelector: "";
  @each $unit in $state {
    $currentSelector: #{$currentSelector + & + '.' + $state-prefix + $unit + ","};
  }
  @at-root #{$currentSelector} {
    @content
  }
}

@mixin pseudo($pseudo) {
  $currentSelector: "";
  @each $unit in $pseudo {
    $currentSelector: #{$currentSelector + & + ':' + $unit + ","};
  }
  @at-root #{$currentSelector} {
    @content
  }
}
