// new design's colors
$pst-black: #000000;
$pst-white: #ffffff;
$pst-gray: #80868B;
$pst-light-gray: #D7D8D9;
$pst-purple: #7421d9;
$pst-purple--hover: #9c4dff;
$pst-yellow: #ffd659;
$pst-yellow--hover: #ffd040;
$pst-reddish-pink: #FA3E5F;
$pst-reddish-pink--hover: #FF4D6B;
// old design's colors.

$pst-purple-blue: #721fd9;
$pst-unknown-pink: #ed3a54;
$pst-brown-grey: #7f7f7f; //2
$pst-very-light-pink: #cccccc;
$pst-unknown-grey: #f6f6f6;
$pst-color: $pst-black;


/* Typography
-------------------------- */
$pst-font-family: Inter, --apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", Verdana, sans-serif;
$pst-font-size-xxx-large: 50px;
$pst-font-size-xx-large: 35px;
$pst-font-size-x-large: 25px;
$pst-font-size-large: 14px;
$pst-font-size-medium: 16px;
$pst-font-size-base: 14px;
$pst-font-weight-primary: normal;
$pst-font-weight-semi-bold: 600;
$pst-font-weight-extra-bold: 800;
$pst-font-weight-black: 900;
$pst-font-line-height-base: 20px;
$pst-font-line-height-medium: 25px;
$pst-font-line-height-x-large: 45px;
$pst-font-color-disabled-base: $pst-gray;
$pst-font-color-base: $pst-black;

// transitions
//$pst-transition-fast: 250ms;
//$pst-transition-middle: 500ms;
//$pst-transition-slow: 750ms;

/* Break-point
--------------------------*/
$--sm: 640px !default;
$--md: 960px !default;
$--lg: 1200px !default;
$--xl: 1800px !default;

$--breakpoints: (
  'xs' : (max-width: $--sm - 1), // mobile
  'sm' : (min-width: $--sm),     // tablet portrait
  'md' : (min-width: $--md),     // tablet landscape
  'lg' : (min-width: $--lg),     // desktop
  'xl' : (min-width: $--xl)      // big desktop
);

$--breakpoints-spec: (
  'xs-only' : (max-width: $--sm - 1),
  'sm-and-up' : (min-width: $--sm),
  'sm-only': "(min-width: #{$--sm}) and (max-width: #{$--md - 1})",
  'sm-and-down': (max-width: $--md - 1),
  'md-and-up' : (min-width: $--md),
  'md-only': "(min-width: #{$--md}) and (max-width: #{$--lg - 1})",
  'md-and-down': (max-width: $--lg - 1),
  'lg-and-up' : (min-width: $--lg),
  'lg-only': "(min-width: #{$--lg}) and (max-width: #{$--xl - 1})",
  'lg-and-down': (max-width: $--xl - 1),
  'xl-only' : (min-width: $--xl),
);

/* bem config var
-------------------------*/
$namespace: 'pst';
$element-separator: '__';
$modifier-separator: '--';
$state-prefix: 'is-';

