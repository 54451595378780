@import '../../../assets/scss/abstract';

@include b(icon) {
    display: flex;
    justify-content: center;

    background: none;

    svg {
        display: block;
        margin: auto;
    }

    @include m(md) {
        width: 26px;
        height: 26px;
    }

    @include m(sm){
        width: inherit;
        height: inherit;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    @include m(left) {
        transform: scale(-1, -1);
    }

    @include m(right) {
        transform: scale(-1, -1);
    }

    @include m(up) {
        transform: scale(-1, -1) rotate(90deg);
    }

    @include m(down) {
        transform: scale(1, 1) rotate(90deg);
    }

    @include m(outlined) {
        box-sizing: border-box;
        border: 4px solid $pst-yellow;
        border-radius: 50%;
        transition: all .2s;

        &:hover {
            border-width: 2px;
        }
    }

    @include m(disabled) {
        filter: grayscale(100%);
        opacity: .25;
        cursor: not-allowed;

        @include pseudo(hover) {
            background-color: transparent;
        }
    }

    @include e(Share) {

        svg {
            margin-left: -2px;
        }
    }

    // @include m(white) {
    //     stroke: white;
    // }

    // @include e(zone) {
    //     stroke: white;
    // }
}
