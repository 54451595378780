
/* Break-points
 -------------------------- */
@mixin res($key, $map: $--breakpoints-spec) {
  @each $unit in $key {
    @if map-has-key($map, $unit) {
      @media only screen and #{inspect(map-get($map, $unit))} {
        @content;
      }
    } @else {
      @warn "Undefined points: `#{$map}`";
    }
  }
}
