@import "../vars/variables";

* {
  outline: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-tap-highlight-color: transparent;
}

html,
body {
  font-size: $pst-font-size-base;
  height: 100%;
  width: 100%;
}

body {
  font-family: $pst-font-family;
  font-style: normal;
  font-weight: $pst-font-weight-primary;
  font-size: $pst-font-size-base;
  color: $pst-font-color-base;
}
a {
  color: inherit;
  text-decoration: none;
}

@media (min-width: 1600px) {
  html {
    font-size: 0.9625vw;
  }
}
