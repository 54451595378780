*,:before,:after {
  --tw-border-spacing-x: 0;
  --tw-border-spacing-y: 0;
  --tw-translate-x: 0;
  --tw-translate-y: 0;
  --tw-rotate: 0;
  --tw-skew-x: 0;
  --tw-skew-y: 0;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  --tw-pan-x: ;
  --tw-pan-y: ;
  --tw-pinch-zoom: ;
  --tw-scroll-snap-strictness: proximity;
  --tw-gradient-from-position: ;
  --tw-gradient-via-position: ;
  --tw-gradient-to-position: ;
  --tw-ordinal: ;
  --tw-slashed-zero: ;
  --tw-numeric-figure: ;
  --tw-numeric-spacing: ;
  --tw-numeric-fraction: ;
  --tw-ring-inset: ;
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: rgb(59 130 246 / .5);
  --tw-ring-offset-shadow: 0 0 #0000;
  --tw-ring-shadow: 0 0 #0000;
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
}

::backdrop {
  --tw-border-spacing-x: 0;
  --tw-border-spacing-y: 0;
  --tw-translate-x: 0;
  --tw-translate-y: 0;
  --tw-rotate: 0;
  --tw-skew-x: 0;
  --tw-skew-y: 0;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  --tw-pan-x: ;
  --tw-pan-y: ;
  --tw-pinch-zoom: ;
  --tw-scroll-snap-strictness: proximity;
  --tw-gradient-from-position: ;
  --tw-gradient-via-position: ;
  --tw-gradient-to-position: ;
  --tw-ordinal: ;
  --tw-slashed-zero: ;
  --tw-numeric-figure: ;
  --tw-numeric-spacing: ;
  --tw-numeric-fraction: ;
  --tw-ring-inset: ;
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: rgb(59 130 246 / .5);
  --tw-ring-offset-shadow: 0 0 #0000;
  --tw-ring-shadow: 0 0 #0000;
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  --tw-blur: ;
  --tw-brightness: ;
  --tw-contrast: ;
  --tw-grayscale: ;
  --tw-hue-rotate: ;
  --tw-invert: ;
  --tw-saturate: ;
  --tw-sepia: ;
  --tw-drop-shadow: ;
  --tw-backdrop-blur: ;
  --tw-backdrop-brightness: ;
  --tw-backdrop-contrast: ;
  --tw-backdrop-grayscale: ;
  --tw-backdrop-hue-rotate: ;
  --tw-backdrop-invert: ;
  --tw-backdrop-opacity: ;
  --tw-backdrop-saturate: ;
  --tw-backdrop-sepia: ;
  --tw-contain-size: ;
  --tw-contain-layout: ;
  --tw-contain-paint: ;
  --tw-contain-style:
}

*,:before,:after {
  box-sizing: border-box;
  border-width: 0;
  border-style: solid;
  border-color: #e5e7eb
}

:before,:after {
  --tw-content: ""
}

html,:host {
  line-height: 1.5;
  -webkit-text-size-adjust: 100%;
  -moz-tab-size: 4;
  -o-tab-size: 4;
  tab-size: 4;
  font-family: ui-sans-serif,system-ui,sans-serif,"Apple Color Emoji","Segoe UI Emoji",Segoe UI Symbol,"Noto Color Emoji";
  font-feature-settings: normal;
  font-variation-settings: normal;
  -webkit-tap-highlight-color: transparent
}

body {
  margin: 0;
  line-height: inherit
}

hr {
  height: 0;
  color: inherit;
  border-top-width: 1px
}

abbr:where([title]) {
  -webkit-text-decoration: underline dotted;
  text-decoration: underline dotted
}

h1,h2,h3,h4,h5,h6 {
  font-size: inherit;
  font-weight: inherit
}

a {
  color: inherit;
  text-decoration: inherit
}

b,strong {
  font-weight: bolder
}

code,kbd,samp,pre {
  font-family: ui-monospace,SFMono-Regular,Menlo,Monaco,Consolas,Liberation Mono,Courier New,monospace;
  font-feature-settings: normal;
  font-variation-settings: normal;
  font-size: 1em
}

small {
  font-size: 80%
}

sub,sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline
}

sub {
  bottom: -.25em
}

sup {
  top: -.5em
}

table {
  text-indent: 0;
  border-color: inherit;
  border-collapse: collapse
}

button,input,optgroup,select,textarea {
  font-family: inherit;
  font-feature-settings: inherit;
  font-variation-settings: inherit;
  font-size: 100%;
  font-weight: inherit;
  line-height: inherit;
  letter-spacing: inherit;
  color: inherit;
  margin: 0;
  padding: 0
}

button,select {
  text-transform: none
}

button,input:where([type=button]),input:where([type=reset]),input:where([type=submit]) {
  -webkit-appearance: button;
  background-color: transparent;
  background-image: none
}

:-moz-focusring {
  outline: auto
}

:-moz-ui-invalid {
  box-shadow: none
}

progress {
  vertical-align: baseline
}

::-webkit-inner-spin-button,::-webkit-outer-spin-button {
  height: auto
}

[type=search] {
  -webkit-appearance: textfield;
  outline-offset: -2px
}

::-webkit-search-decoration {
  -webkit-appearance: none
}

::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit
}

summary {
  display: list-item
}

blockquote,dl,dd,h1,h2,h3,h4,h5,h6,hr,figure,p,pre {
  margin: 0
}

fieldset {
  margin: 0;
  padding: 0
}

legend {
  padding: 0
}

ol,ul,menu {
  list-style: none;
  margin: 0;
  padding: 0
}

dialog {
  padding: 0
}

textarea {
  resize: vertical
}

input::-moz-placeholder,textarea::-moz-placeholder {
  opacity: 1;
  color: #9ca3af
}

input::placeholder,textarea::placeholder {
  opacity: 1;
  color: #9ca3af
}

button,[role=button] {
  cursor: pointer
}

:disabled {
  cursor: default
}

img,svg,video,canvas,audio,iframe,embed,object {
  display: block;
  vertical-align: middle
}

img,video {
  max-width: 100%;
  height: auto
}

[hidden]:where(:not([hidden=until-found])) {
  display: none
}

.m-auto {
  margin: auto
}

.mx-auto {
  margin-left: auto;
  margin-right: auto
}

.mb-5 {
  margin-bottom: 1.25rem
}

.flex {
  display: flex
}

.h-16 {
  height: 4rem
}

.h-24 {
  height: 6rem
}

.h-full {
  height: 100%
}

.max-h-0 {
  max-height: 0px
}

.max-h-72 {
  max-height: 25rem
}

.min-h-8 {
  min-height: 2rem
}

.w-16 {
  width: 4rem
}

.w-24 {
  width: 6rem
}

.w-full {
  width: 100%
}

.max-w-\[1200px\] {
  max-width: 1200px
}

.max-w-\[80\%\] {
  max-width: 80%
}

.flex-1 {
  flex: 1 1 0%
}

.cursor-pointer {
  cursor: pointer
}

.flex-col {
  flex-direction: column
}

.items-center {
  align-items: center
}

.justify-center {
  justify-content: center
}

.justify-between {
  justify-content: space-between
}

.gap-1 {
  gap: .25rem
}

.gap-2 {
  gap: .5rem
}

.gap-3 {
  gap: .75rem
}

.gap-4 {
  gap: 1rem
}

.gap-5 {
  gap: 1.25rem
}

.gap-9 {
  gap: 2.25rem
}

.overflow-hidden {
  overflow: hidden
}

.overflow-y-auto {
  overflow-y: auto
}

.rounded-md {
  border-radius: .375rem
}

.rounded-sm {
  border-radius: .125rem
}

.bg-\[\#2e2d2d\] {
  --tw-bg-opacity: 1;
  background-color: rgb(46 45 45 / var(--tw-bg-opacity, 1))
}

.bg-\[\#4c4848\] {
  --tw-bg-opacity: 1;
  background-color: rgb(76 72 72 / var(--tw-bg-opacity, 1))
}

.bg-\[\#a66646\] {
  --tw-bg-opacity: 1;
  background-color: rgb(166 102 70 / var(--tw-bg-opacity, 1))
}


.bg-\[\#7421D9\] {
  --tw-bg-opacity: 1;
  background-color: #7421D9
}

.bg-gray-200 {
  --tw-bg-opacity: 1;
  background-color: rgb(229 231 235 / var(--tw-bg-opacity, 1))
}

.bg-gray-300 {
  --tw-bg-opacity: 1;
  background-color: rgb(209 213 219 / var(--tw-bg-opacity, 1))
}

.object-cover {
  -o-object-fit: cover;
  object-fit: cover
}

.p-\[0\.5rem_10px\] {
  padding: .5rem 10px
}

.text-3xl {
  font-size: 1.875rem;
  line-height: 2.25rem
}

.text-sm {
  font-size: .875rem;
  line-height: 1.25rem
}

.text-xl {
  font-size: 1.25rem;
  line-height: 1.75rem
}

.font-bold {
  font-weight: 700
}

.text-\[\#f50\] {
  --tw-text-opacity: 1;
  color: rgb(255 85 0 / var(--tw-text-opacity, 1))
}

.text-gray-400 {
  --tw-text-opacity: 1;
  color: rgb(156 163 175 / var(--tw-text-opacity, 1))
}

.text-gray-600 {
  --tw-text-opacity: 1;
  color: rgb(75 85 99 / var(--tw-text-opacity, 1))
}

.text-white {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity, 1))
}

.duration-300 {
  transition-duration: .3s
}

.ease-in-out {
  transition-timing-function: cubic-bezier(.4,0,.2,1)
}

@media (min-width: 1024px) {
  .lg\:max-w-64 {
    max-width:16rem
  }

  .lg\:flex-row {
    flex-direction: row
  }

  .lg\:truncate {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap
  }
}

input[type=range] {
  --range-progress: 0;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  position: relative;
  background: #ccc;
  width: 100%;
  height: 2px;
  border-radius: 2px;
  cursor: pointer
}

input[type=range]::-moz-range-track {
  position: relative;
  background: #ccc;
  width: 100%;
  height: 2px;
  border-radius: 2px;
  cursor: pointer
}

input[type=range]:before {
  content: "";
  height: 2px;
  background: #f50;
  width: var(--range-progress);
  border-bottom-left-radius: 2px;
  border-top-left-radius: 2px;
  position: absolute;
  top: 0;
  left: 0
}

input[type=range]::-moz-range-progress {
  background: #f50;
  border-bottom-left-radius: 2px;
  border-top-left-radius: 2px;
  height: 2px
}

input[type=range]::-webkit-slider-thumb {
  -webkit-appearance: none;
  height: 8px;
  width: 8px;
  border-radius: 50%;
  border: none;
  cursor: pointer;
  position: relative
}

.volumn::-webkit-slider-thumb {
  background-color: #f50
}

input[type=range]:active::-webkit-slider-thumb {
  transform: scale(1.2);
  background-color: #f50
}

input[type=range]::-moz-range-thumb {
  height: 8px;
  width: 8px;
  border-radius: 50%;
  background: #f50;
  cursor: pointer;
  border: transparent;
  position: relative
}

input[type=range]:active::-moz-range-thumb {
  transform: scale(1.2);
  background-color: #f50
}

.flex-shrink-0 {
  flex-shrink: 0;
}

.volume-control {
  padding-top: 30px;
}
